import React, { useEffect, useState } from 'react';
import { GoogleMap, Marker, InfoWindow, useLoadScript } from '@react-google-maps/api';
import { req } from '../../utils';
import { Card, CardContent, CardHeader } from '@mui/material';

const mapContainerStyle = {
  height: "500px",
  width: "100%"
};

const center = {
  lat: -30,
  lng: 144
};

export const GoogleMapServiceCalls = ({ filterProps }) => {
	const {
        dateRanges,
        pageFilterByType,
        pageFilterByState,
        selectedDateRange,
	} = filterProps;
  
	const [loading, setLoading] = useState(true);
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
	});

	const [serviceCalldata, setServiceCalldata] = useState([]);
	const [selectedCall, setSelectedCall] = useState(null);

	const getData = async () => {
		if (!dateRanges) return;

		try {
		  const startDate = dateRanges[0] ? Math.floor(new Date(dateRanges[0]).getTime() / 1000) : Math.floor(Date.now() / 1000) - 30 * 24 * 60 * 60;
      const endDate = dateRanges[1] ? Math.floor(new Date(dateRanges[1]).getTime() / 1000) : Math.floor(Date.now() / 1000);
      const url = `service_calls_map?s=${startDate}&e=${endDate}`;

			let data = await req("GET", url);
			setServiceCalldata(data);
			//console.log("Service Calls Map Data: ", data);
		} catch (e) {
			console.error(e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData();
	}, [dateRanges]);


  
  	// Render null or loading indicator if data is not available
	if (!isLoaded || loading) {
		return <div>Loading...</div>;
	}
  //console.log("Service Calls Map Data: ", serviceCalldata);
  	return (
    <Card>

      <CardContent sx={{ padding: '0 !important' }}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={5}
          center={center}
          options={{ scrollwheel: true }} 
          >
          {serviceCalldata.map(call => (
            <Marker
            key={call.bc_call_code}
            position={{ lat: parseFloat(call.latitude), lng: parseFloat(call.longitude) }}
            onClick={() => setSelectedCall(call)}
            icon={{
              url: getIconForServiceCallType(call.service_call_type),
              scaledSize: new window.google.maps.Size(32, 32),
            }}
            />
          ))}

          {selectedCall && (
            <InfoWindow
            position={{ lat: parseFloat(selectedCall.latitude), lng: parseFloat(selectedCall.longitude) }}
            onCloseClick={() => setSelectedCall(null)}
            >
              <div>
                <h2>Call id: {selectedCall.bc_call_code}</h2>
                <p> {selectedCall.venue_name}</p>
                <p> {selectedCall.service_call_type}</p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </CardContent>
    </Card>
  );
};
function getIconForServiceCallType(call_type) {
    if(call_type === 'BREAKDOWN') return '/assets/images/icons/1558-doctor-bag_4x.png';
    if(call_type === 'Preventative Maintenance') return '/assets/images/icons/1590-hardware-wrench_4x.png';
    if(call_type === 'Glass collection') return '/assets/images/icons/1517-bar-cocktail_4x.png';
    if(call_type === 'Installation') return '/assets/images/icons/1722-truck_4x.png';
    if(call_type === 'Warehouse visit') return '/assets/images/icons/1565-factory_4x.png';
    return '/assets/images/icons/1827-message-in-a-bottle_4x.png';
}

function getColorBasedOnMachines(call_type) {
  //console.log('Call type',call_type);
  if(call_type === 'BREAKDOWN') return '#FF4136'; // Red
  if(call_type === 'Preventative Maintenance' || call_type==="Preventative Maintenance" || call_type==="Preventive maintenance") return '#FFDC00'; // Yellow
  if(call_type === 'Glass collection' || call_type ==="Collection Change" || call_type ==="Additional Bin Request") return '#0074D9'; // Blue
  if(call_type === 'Installation' || call_type ==="Installation") return '#2ECC40'; // Green
  if(call_type === 'Warehouse visit') return '#FF851B'; // Orange
    return '#00A49B'; // Default color for 2 or fewer machines
}
