import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { req } from "../../utils";
import { Grid } from "@mui/material/";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import fileContractIcon from "../../assets/images/file-contract-regular.svg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const purplePalette = [
  "#B3A6FF",  // Lavender
  "#9C6BFF",  // Amethyst
  "#6F32C8"   // Royal Purple
];

const bluePalette = [
  "#6EC6FF",  // Sky Blue
  "#5AA2D4",  // Ocean Blue
  "#274A78"   // Midnight Blue
];

const orangePalette = [
  "#FFC19E",  // Soft Peach
  "#FF8A3D",  // Amber
  "#D45A2A"   // Burnt Orange
];
export const PipedriveLeads = ({ filterProps }) => {
  const { dateRanges, pageFilterByType } = filterProps;
  const [leadsStats, setLeadsStats] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [lastFetchedDateRanges, setLastFetchedDateRanges] = useState(null);

  const generateLeadStats = useMemo(() => {
    return (leadsData, startDate = null, endDate = null) => {
      const start = startDate ? new Date(startDate) : null;
      const end = endDate ? new Date(endDate) : new Date();

      const filteredLeads = leadsData.map((bdm) => {
        return {
          BDM: bdm.BDM,
          Leads: bdm.Leads.filter((lead) => {
            const leadAddDate = new Date(lead.add_time);
            return (!start || leadAddDate >= start) && leadAddDate <= end;
          }),
        };
      });

      // Total Leads Per BDM
      const totalLeadsPerBDM = filteredLeads.map((bdm) => ({
        name: bdm.BDM,
        number: bdm.Leads.length,
      }));

      //search through all leads where update_time is in the date range and count them
      const totalLeadsInTimeRange = filteredLeads.reduce(
        (total, bdm) =>
          total +
          bdm.Leads.filter((lead) => {
            const leadUpdateDate = new Date(lead.add_time);
            return leadUpdateDate >= start && leadUpdateDate <= end;
          }).length,
        0
      );

      // Lead Creation Rate (defaulting to daily)
      const leadCreationRate = (interval = "day") => {
        const creationRates = {};
        
        filteredLeads.forEach((bdm) => {
          bdm.Leads.forEach((lead) => {
            const leadDate = new Date(lead.add_time);
            let key;
            if (interval === "day") {
              key = leadDate.toISOString().split("T")[0];
            } else if (interval === "month") {
              key = `${leadDate.getFullYear()}-${leadDate.getMonth() + 1}`;
            } else if (interval === "week") {
              const week = Math.ceil(leadDate.getDate() / 7);
              key = `${leadDate.getFullYear()}-W${week}`;
            }
            if (!creationRates[key]) {
              creationRates[key] = {};
            }
            creationRates[key][bdm.BDM] = (creationRates[key][bdm.BDM] || 0) + 1;
          });
        });

        // Add BDMs with zero leads to each date
        Object.keys(creationRates).forEach((date) => {
          filteredLeads.forEach((bdm) => {
            if (!creationRates[date][bdm.BDM]) {
              creationRates[date][bdm.BDM] = 0;
            }
          });
        });

        return creationRates;
      };

      // Finding Most Recent Lead
      const mostRecentLead = filteredLeads.reduce((mostRecent, bdm) => {
        const recentLead = bdm.Leads.reduce((latest, lead) => {
          const leadAddDate = new Date(lead.add_time);
          return leadAddDate > new Date(latest.add_time) ? lead : latest;
        }, bdm.Leads[0]);
        return recentLead &&
          (!mostRecent ||
            new Date(recentLead.add_time) > new Date(mostRecent.add_time))
          ? recentLead
          : mostRecent;
      }, null);

      // Return the stats in the requested format
      return {
        mostRecentLead: mostRecentLead,
        totalLeadsPerBDM: totalLeadsPerBDM,
        totalLeadsInTimeRange: totalLeadsInTimeRange,
        leadCreationRate: leadCreationRate("day"),
      };
    };
  }, []);

  useEffect(() => {
    const fetchLeadsData = async () => {
      setIsLoading(true);
      try {
        const startDate = dateRanges
          ? moment(dateRanges[0]).unix()
          : moment().subtract(30, "days").unix();
        const endDate = dateRanges
          ? moment(dateRanges[1]).unix()
          : moment().unix();
        const url = `get_leads?s=${startDate}&e=${endDate}`;
        const data = await req("GET", url);
        
        if (data && data.length > 0) {
          const startDateParam = moment.unix(startDate).format("YYYY-MM-DD");
          const endDateParam = moment.unix(endDate).format("YYYY-MM-DD");
          const newLeadsStats = generateLeadStats(data, startDateParam, endDateParam);
          setLeadsStats(newLeadsStats);
          setLastFetchedDateRanges(dateRanges);
        } else {
          setLeadsStats({ totalLeadsInTimeRange: 0, leadCreationRate: {} });
        }
      } catch (error) {
        console.error("Error fetching leads data:", error);
        setLeadsStats(prev => prev || { totalLeadsInTimeRange: 0, leadCreationRate: {} });
      } finally {
        setIsLoading(false);
      }
    };

    if (dateRanges !== lastFetchedDateRanges) {
      fetchLeadsData();
    }
  }, [dateRanges, lastFetchedDateRanges, generateLeadStats]);

  const chartData = useMemo(() => {
    if (!leadsStats) return null;
    const sortedDates = Object.keys(leadsStats.leadCreationRate).sort();
    const bdms = [...new Set(leadsStats.totalLeadsPerBDM.map(bdm => bdm.name))];

    const bdmColorMap = {
      "kirk":purplePalette[0],  // Lavender
      "Dennis": bluePalette[0],  // Ocean Blue
      "Matt Miliano": orangePalette[0]  // Amber
    };

    const datasets = bdms.map((bdm) => {
      const data = sortedDates.map(date => leadsStats.leadCreationRate[date][bdm] || 0);
      const hasLeads = data.some(value => value > 0);
      
      return {
        label: bdm,
        data: data,
        backgroundColor: bdmColorMap[bdm] || "#B3A6FF", // Default to Lavender if not specified
        hidden: !hasLeads, // Hide the dataset if there are no leads
      };
    }).filter(dataset => !dataset.hidden); // Remove hidden datasets from the final array

    return {
      labels: sortedDates,
      datasets: datasets,
    };
  }, [leadsStats]);

  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      title: {
        display: true,
        text: "Lead Creation Rate by BDM",
      },
    },
  };

  const formatDate = (date) => moment(date).format("DD/MM/YYYY");

  if (isLoading && !leadsStats) return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={6}

      className={`${
        pageFilterByType !== "unfiltered" && pageFilterByType !== "contract"
          ? "d-none"
          : ""
      }`}
    >
      <div className="card card-stat-summary animate-bg-primary">
        <div className="card-body">
          <div className="card-stat-summary--title">PipeDrive Leads</div>
          <div className="card-stat-summary--total-wrapper">
            <div>Loading...</div>
          </div>
        </div>
        <div className="card-bg">
          <img
            src={fileContractIcon}
            alt="File Contract Icon"
            className="bg-icon"
          />
        </div>
      </div>
    </Grid>
  );

  if (!leadsStats) return null;

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={6}
      className={`${
        pageFilterByType !== "unfiltered" && pageFilterByType !== "contract"
          ? "d-none"
          : ""
      }`}
    >
      <div className="card card-stat-summary">
        <div className="card-body">
          <div className="card-stat-summary--title">PipeDrive Leads</div>
          <div className="card-stat-summary--total-wrapper"></div>
          <div className="card-stat-summary--total-wrapper">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6}>
                <div className="card-stat-summary--total">
                  {leadsStats.totalLeadsInTimeRange}
                  <div className="card-stat-summary--total-subtitle">
                    Total leads
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          {chartData && (
            <div className="lead-creation-chart">
              <Bar options={chartOptions} data={chartData} />
            </div>
          )}
          
          {leadsStats.mostRecentLead && leadsStats.mostRecentLead.title && (
            <div className="most-recent-lead" style={{ marginTop: '1rem' }}>
              <h4>Most Recent Lead</h4>
              <p><strong>Title:</strong> {leadsStats.mostRecentLead.title}</p>
              <p><strong>Added:</strong> {formatDate(leadsStats.mostRecentLead.add_time)}</p>
              <p><strong>BDM:</strong> {leadsStats.mostRecentLead.owner}</p>
            </div>
          )}
      
          <div className="card-stat-summary--meta">
            <div className="card-stat-summary--meta-timeline">
              <span className="text-persiangreen">Date range:</span>{" "}
              {formatDate(dateRanges[0])} - {formatDate(dateRanges[1])}
            </div>
          </div>
        </div>
        <div className="card-bg">
          <img
            src={fileContractIcon}
            alt="File Contract Icon"
            className="bg-icon"
          />
        </div>
      </div>
    </Grid>
  );
};
