import React, { useState, useEffect } from "react";

import { styled } from "@mui/system";
import {
  Box,
  Container,
  Grid,
  Paper,
  useMediaQuery,
  useTheme,
  Typography,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";

import { useDataContext } from "../DataContext";
import { Link } from "react-router-dom";
import DateRange from "../components/DateRange";
import Filters from "../components/Filters";

import { ReportItem } from "../components/ReportItem";
import { BinsCollected } from "../components/reports/BinsCollected";
import { Machines } from "../components/reports/Machines";
import { Bc2Counts } from "../components/reports/Bc2Counts";
import { ServiceCallInsights } from "../components/reports/ServiceCallInsights";
import { PendingInstallsRemovals } from "../components/reports/PendingInstallsRemovals";
import { PipedriveLeads } from "../components/reports/PipedriveLeads";
import { VenuesWithMachines } from "../components/reports/VenuesWithMachines";
import { EnvironmentalImpact } from "../components/reports/EnvironmentalInpact";
import { ScanRatioChart } from "../components/reports/ScanRatioChart";
import { Contracts } from "../components/reports/Contracts";
//import { Condition36 } from "../components/reports/Condition36";
import { GoogleMapServiceCalls } from "../components/reports/GoogleMapServiceCalls";
import { ServiceCallTimes } from "../components/reports/ServiceCallTimes";
import { PmQueue } from "../components/reports/PmQueue";
import { ServiceTechJobsCompleted } from "../components/reports/ServiceTechJobsCompleted";
import { MachineInstallsRemovals } from "../components/reports/MachineInstallsRemovals";
import { PipedriveDeals } from "../components/reports/PipedriveDeals";
import { OffContractService } from "../components/reports/OffContractService";

import serviceCallIcon from "../assets/images/phone-rotary-regular.svg";
import machineIcon from "../assets/images/dryer-alt-regular.svg";

import { Tabs, Tab } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const Dashboards = () => {
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const { dateRanges } = useDataContext();

  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const matchesLG = useMediaQuery(theme.breakpoints.down("lg"));

  const handleDateRangeChange = (dateRange) => {
    setSelectedDateRange(dateRange);
  };

  const getColumnCount = () => {
    if (matchesSM) return 1;
    if (matchesMD) return 2;
    return 3; // for matchesLG and up
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  }));

  /* Filters */
  const [pageFilterByType, setPageFilterByType] = useState("unfiltered");
  const [pageFilterByState, setPageFilterByState] = useState("unfiltered");

  const handleApplyFilters = (type, state) => {
    setPageFilterByType(type);
    setPageFilterByState(state);
  };

  /* Filter Props */
  const filterProps = {
    dateRanges,
    pageFilterByType,
    pageFilterByState,
    selectedDateRange,
  };
  useEffect(() => {
    //site document title
    document.title = "Dashboards - last updated 05/09/2024";
  }, [filterProps]);
  const [mapTabValue, setMapTabValue] = useState(0);

  const handleMapTabChange = (event, newValue) => {
    setMapTabValue(newValue);
  };

  const sectionTitleStyle = {
    borderBottom: "2px solid #00A49B",
    fontSize: "1.7rem",
    marginBottom: "1rem !important",
    paddingBottom: ".5rem",
    mariginTop: "1rem!important",
    //bolder
    fontWeight: "bolder",
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    const stickyHeader = document.querySelector(".sticky-page-header");
    if (section && stickyHeader) {
      const headerHeight = stickyHeader.offsetHeight;
      const offset = 70;
      const sectionPosition =
        section.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: sectionPosition - headerHeight - offset,
        behavior: "smooth",
      });
    }
    handleClose();
  };

  return (
    <main className="bc-page--content dashboard">
      <div className="sticky-page-header">
        <Container maxWidth="xl">
          <Grid container spacing={{ xs: 1, sm: 2, md: 3 }} alignItems="center">
            <Grid
              item
              xs={isMobile ? 6 : 8}
              display="flex"
              justifyContent="start"
            >
              {isMobile ? (
                <Button
                  endIcon={<ArrowDropDownIcon />}
                  onClick={handleClick}
                  color="persiangreen"
                  sx={{ textTransform: "none" }}
                >
                  Departments
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() => scrollToSection("sales-section")}
                    color="persiangreen"
                    sx={{ color: "persiangreen", textTransform: "none" }}
                  >
                    Sales
                  </Button>
                  <Button
                    onClick={() => scrollToSection("service-section")}
                    color="persiangreen"
                    sx={{ textTransform: "none" }}
                  >
                    Service
                  </Button>
                  <Button
                    onClick={() => scrollToSection("environment-section")}
                    color="persiangreen"
                    sx={{ textTransform: "none" }}
                  >
                    Environment
                  </Button>
                  <Button
                    onClick={() => scrollToSection("maps-section")}
                    color="persiangreen"
                    sx={{ textTransform: "none" }}
                  >
                    Maps
                  </Button>
                </>
              )}
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => scrollToSection("sales-section")}>
                  Sales
                </MenuItem>
                <MenuItem onClick={() => scrollToSection("service-section")}>
                  Service
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection("environment-section")}
                >
                  Environment
                </MenuItem>
                <MenuItem onClick={() => scrollToSection("maps-section")}>
                  Maps
                </MenuItem>
              </Menu>
            </Grid>
            <Grid
              item
              xs={isMobile ? 6 : 4}
              display="flex"
              justifyContent="end"
            >
              <DateRange onChange={handleDateRangeChange} />
              <Filters onApplyFilters={handleApplyFilters} />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Box className="bg-lightest-grey" sx={{ mt: 3 }}>
        <Container maxWidth="xl" sx={{ py: { xs: 2, md: 4 } }}>
          {/* Sales Section */}
          <Box id="sales-section" sx={{ mb: 4 }}>
            <Typography variant="h4" component="h2" sx={sectionTitleStyle}>
              Sales
            </Typography>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <PipedriveLeads filterProps={filterProps} />
              <PipedriveDeals filterProps={filterProps} />
              <Contracts filterProps={filterProps} />
              <OffContractService filterProps={filterProps} />
              <MachineInstallsRemovals filterProps={filterProps} />
            </Grid>
          </Box>

          {/* Service Section */}
          <Box id="service-section" sx={{ mb: 4 }}>
            <Typography variant="h4" component="h2" sx={sectionTitleStyle}>
              Service
            </Typography>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <Grid item xs={12}>
                <ServiceCallInsights filterProps={filterProps} />
              </Grid>
              <Grid item xs={6}>
                <Machines filterProps={filterProps} />
              </Grid>
              <Grid item xs={6}>
                <PendingInstallsRemovals filterProps={filterProps} />
              </Grid>
            </Grid>
            {(pageFilterByType === "call" ||
              pageFilterByType === "unfiltered") && (
              <Grid
                container
                spacing={3}
              >
                <Grid item xs={12}>
                  <PmQueue filterProps={filterProps} />
                </Grid>
                <Grid item xs={12}>
                  <ServiceTechJobsCompleted dateRanges={dateRanges} />
                </Grid>
                <Grid item xs={12}>
                  <ServiceCallTimes dateRanges={dateRanges} />
                </Grid>
              </Grid>
            )}
          </Box>

          {/* Environment Section */}
          <Box id="environment-section" sx={{ mb: 4 }}>
            <Typography variant="h4" component="h2" sx={sectionTitleStyle}>
              Environment
            </Typography>
            <Grid container spacing={{ xs: 2, md: 3 }}>
              <EnvironmentalImpact filterProps={filterProps} />
              <BinsCollected filterProps={filterProps} />
              <Bc2Counts filterProps={filterProps} />
            </Grid>
          </Box>

          {/* Machine Section */}
          {(pageFilterByType === "machine" ||
            pageFilterByType === "unfiltered") && (
            <Box id="maps-section">
              <Typography variant="h4" component="h2" sx={sectionTitleStyle}>
                Maps
              </Typography>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                
                <Grid item xs={12}>
                <Paper>
                  <Tabs 
                    value={mapTabValue} 
                    onChange={handleMapTabChange} 
                    variant="fullWidth"
                    sx={{
                      '& .MuiTabs-flexContainer': {
                        justifyContent: 'flex-start',
                      },
                    }}
                  >
                    <Tab label="Venues Map" sx={{ flexGrow: 1 }} />
                    <Tab label="Service Call Map" sx={{ flexGrow: 1 }} />
                  </Tabs>
                  <Box>
                    <Box display={mapTabValue === 0 ? 'block' : 'none'}>
                      <VenuesWithMachines />
                    </Box>
                    <Box display={mapTabValue === 1 ? 'block' : 'none'}>
                      <GoogleMapServiceCalls filterProps={filterProps} />
                    </Box>
                  </Box>
                </Paper>
                </Grid>
              </Grid>
            </Box>
          )}
        </Container>
      </Box>
    </main>
  );
};

export default Dashboards;
