import React from 'react';
import { 
  DataGrid, 
  GridToolbar
} from '@mui/x-data-grid';
import { Box } from '@mui/material';

const DataTable = ({ rows, columns }) => {
  const formattedColumns = columns.map(column => ({
    ...column,
    renderCell: (params) => {
      if (column.formatter && typeof column.formatter === 'function') {
        return column.formatter(params.value, params.row);
      }
      return params.value;
    }
  }));

  const getRowId = (row, index) => {
    return Math.random() * 10000000000000000;
    return row?.id || row?.customer_id || index;
  };

  return (
    <Box sx={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={formattedColumns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        // checkboxSelection removed
        disableSelectionOnClick
        components={{
          Toolbar: GridToolbar,
        }}
        getRowId={getRowId}
      />
    </Box>
  );
};

export default DataTable;