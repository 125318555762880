import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, Grid, Tabs, Tab, Box, CircularProgress } from '@mui/material';
import { req } from '../../utils';
import DataTable from '../DataTable';

export const OffContractService = ({ filterProps }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await req('get', 'get_off_contract_servicing');
        setData(response);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TabPanel = ({ children, value, index }) => (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );



  const bin_collections_without_contract_columns = [
    { field: 'myob_record_id', headerName: 'MYOB', width: 70 },
    { 
      field: 'customer_name', 
      headerName: 'Customer', 
      width: 200,
      formatter: (value, row) => {
        // Custom formatting logic for customer name
        return value.toUpperCase();
      }
    },
    { field: 'nbr_of_bins', headerName: 'Bins', width: 150 },
    { 
      field: 'last_date_collected', 
      headerName: 'Last Collection', 
      width: 150,
      formatter: (value, row) => {
        //convert unix timestamp to date
        return new Date(value * 1000).toLocaleDateString();
      }
    },
    // Add more columns as needed
  ];
  const bins_expired_contract_columns = [
    { field: 'myob_record_id', headerName: 'MYOB', width: 70 },
    { 
      field: 'customer_name', 
      headerName: 'Customer', 
      width: 200,
      formatter: (value, row) => {
        return value.toUpperCase();
      }
    },
    { field: 'bins_collected_outside_contract', headerName: 'Bins', width: 150 },
    { 
      field: 'last_pickup_date', 
      headerName: 'Last Collection', 
      width: 150,
      formatter: (value, row) => {
        return new Date(value * 1000).toLocaleDateString();
      }
    },
   {
    field: 'contract_end_date',
    headerName: 'Contract End Date',
    width: 150,
    formatter: (value, row) => {
      return new Date(value * 1000).toLocaleDateString();
    }
   }
  ];
  const servicing_without_contract_columns = [
    { field: 'myob_record_id', headerName: 'MYOB', width: 70 },
    { 
      field: 'account_name', 
      headerName: 'Customer', 
      width: 200,
      formatter: (value, row) => {
        return value.toUpperCase();
      }
    },
    { field: 'nbr_of_calls', headerName: '# Calls', width: 150 },
    { 
      field: 'last_time_completed', 
      headerName: 'Last completion', 
      width: 150,
      formatter: (value, row) => {
        return new Date(value * 1000).toLocaleDateString();
      }
    },
    {
      field: 'last_time_created',
      headerName: 'Last Created',
      width: 150,
      formatter: (value, row) => {
        return new Date(value * 1000).toLocaleDateString(); 
      }
    }
  ];
  const servicing_expired_contract_columns = [
    { field: 'myob_record_id', headerName: 'MYOB', width: 70 },
    { 
      field: 'account_name', 
      headerName: 'Customer', 
      width: 200,
      formatter: (value, row) => {
        return value.toUpperCase();
      }
    },
    { field: 'nbr_of_calls', headerName: '# Calls', width: 150 },
    { 
      field: 'last_time_completed', 
      headerName: 'Last completion', 
      width: 150,
      formatter: (value, row) => {
        return new Date(value * 1000).toLocaleDateString();
      }
    },
    {
      field: 'last_time_created',
      headerName: 'Last Created',
      width: 150,
      formatter: (value, row) => {
        return new Date(value * 1000).toLocaleDateString(); 
      }
    },
    {
      field: 'contract_ended',
      headerName: 'Contract End Date',
      width: 150,
      formatter: (value, row) => {
        return new Date(value * 1000).toLocaleDateString();
      }
    }
  ];

  if (loading) {
    return (
      <Grid item xs={12}>
        <Card >
          <CardHeader title="Off Contract Servicing" />
          <CardContent>
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
              <CircularProgress />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    );
  }

  return (
    <Grid item xs={12}>
      <Card >
        <CardHeader title="Off Contract Servicing" />
        <CardContent>
          <Tabs value={tabValue} onChange={handleTabChange} variant="fullWidth">
            <Tab label="Bins W/O Contract" />
            <Tab label="Bins Expired Contract" />
            <Tab label="Servicing W/O  Contract" />
            <Tab label="Servicing Expired Contract" />
          </Tabs>
          <TabPanel value={tabValue} index={0} className="p-0">
            <DataTable 
              rows={data?.bin_collections_without_contract || []}
              columns={bin_collections_without_contract_columns}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1} className="p-0">
           <DataTable 
              rows={data?.bin_collections_outside_contract
                || []}
              columns={bins_expired_contract_columns}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2} className="p-0">
            <DataTable 
              rows={data?.service_without_contract || []}
              columns={servicing_without_contract_columns}
          
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3} className="p-0">
            <DataTable 
              rows={data?.service_calls_after_contract_end  || []}
              columns={servicing_expired_contract_columns}
            />
          </TabPanel>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default OffContractService;