import React, { useState, useEffect } from 'react';
import { 
    Chart as ChartJS, 
    CategoryScale, 
    LinearScale, 
    BarElement,
    Title, 
    Tooltip, 
    Legend,
    TimeScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Bar } from 'react-chartjs-2';
import {req} from '../../utils.js';
import { generateColorPalette } from "../../utils/generateColorPalette.js";
import { Card, CardContent, CardHeader } from '@mui/material';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    TimeScale
);

export const ServiceTechJobsCompleted = ({ dateRanges }) => {
    const [chartData, setChartData] = useState({
        labels: [],
        datasets: []
    });

    useEffect(() => {
        if(!dateRanges || dateRanges.length === 0) return;

        const fetchData = async () => {
            const start = new Date(dateRanges[0]).getTime() / 1000;
            const end = new Date(dateRanges[1]).getTime() / 1000;

            const result = await req("GET", `get_st_completed_jobs?s=${start}&e=${end}`);
            const jobsPerDay = {};

            // Group data by day and technician
            result.forEach(job => {
                const day = new Date(job.time_completed * 1000).toISOString().split('T')[0]; // Converts timestamp to YYYY-MM-DD
                const techName = job.service_tech;
                if (!jobsPerDay[day]) {
                    jobsPerDay[day] = {};
                }
                if (!jobsPerDay[day][techName]) {
                    jobsPerDay[day][techName] = 0;
                }
                jobsPerDay[day][techName]++;
            });

            // Prepare datasets for the chart
            const datasets = [];
            const allTechs = new Set();
            Object.keys(jobsPerDay).forEach(day => {
            
                Object.keys(jobsPerDay[day]).forEach(tech => {
                    allTechs.add(tech);
                });
            });

            //const colorPalette = generateColorPalette(15);
            const getColors = (tech_id) => {
                console.log(tech_id);
                const purplePalette = [
                    "#B3A6FF",  // Lavender
                    "#9C6BFF",  // Amethyst
                    "#6F32C8"   // Royal Purple
                  ];
                  
                  const bluePalette = [
                    "#6EC6FF",  // Sky Blue
                    "#5AA2D4",  // Ocean Blue
                    "#274A78"   // Midnight Blue
                  ];
                  
                  const orangePalette = [
                    "#FFC19E",  // Soft Peach
                    "#FF8A3D",  // Amber
                    "#D45A2A"   // Burnt Orange
                  ];
                //map of tech to pallette based on the tech id
                const techToPalette = {
                    //QLD
                    "Abram McEvoy": purplePalette[0], //Abram McEvoy - Geoff / Abe / Neil as purples
                    "Geoff Duggan": purplePalette[1], //Geoff - 
                    "Neil Gibson": purplePalette[2], //Neil
                    //NSW = Matty R, Mark Webber,  Le pu Blues
                    "Matthew Rindfleish": bluePalette[0],
                    "Mark Weber": bluePalette[1],
                    "Lee Pu": bluePalette[2],
                    //VIC = Victoria in Orange Matt Miliano, Vim
                    "Matthew Miliano": orangePalette[0],
                    "Vimal Johnson": orangePalette[1],
                    //use a fallback for the rest
                };
                let color = techToPalette[tech_id];
                if(!color) 
                    color = orangePalette[2];
                return color;
            };
            Array.from(allTechs).forEach((tech, index) => {
                const data = Object.keys(jobsPerDay).map(day => ({
                    x: day,
                    y: jobsPerDay[day][tech] || 0
                }));
                let color = getColors(tech);
                datasets.push({
                    label: tech,
                    data,
                    backgroundColor: color,
                    borderColor: color,
                    tension: 0.1
                });
            });

            setChartData({
                labels: Object.keys(jobsPerDay).sort(),
                datasets
            });
        };
        fetchData();
    }, [dateRanges]); // eslint-disable-line react-hooks/exhaustive-deps

    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day'
                },
                title: {
                    display: true,
                    text: 'Date'
                },
                stacked: true
            },
            y: {
                stacked: true,
                title: {
                    display: true,
                    text: 'Number of Jobs'
                }
            }
        },
        plugins: {
            tooltip: {
                mode: 'index'
            }
        }
    };

    return chartData.labels.length ? (
        <Card>
            <CardHeader title="Jobs Completed by Staff" />
            <CardContent
                sx={{
                maxHeight: '600px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                }}
            >
                <Bar data={chartData} options={options} />
            </CardContent>
        </Card>
    ) : null;
};