import React, { useState, useEffect } from "react";
import moment from "moment";
import { req } from "../../utils";
import { Grid } from "@mui/material/";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import fileContractIcon from "../../assets/images/file-contract-regular.svg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const PipedriveDeals  = ({ filterProps }) => {
  const { dateRanges, pageFilterByType } = filterProps;
  const [dealStats, setDealStats] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const purplePalette = [
    "#B3A6FF",  // Lavender
    "#9C6BFF",  // Amethyst
    "#6F32C8"   // Royal Purple
  ];
  
  const bluePalette = [
    "#6EC6FF",  // Sky Blue
    "#5AA2D4",  // Ocean Blue
    "#274A78"   // Midnight Blue
  ];
  
  const orangePalette = [
    "#FFC19E",  // Soft Peach
    "#FF8A3D",  // Amber
    "#D45A2A"   // Burnt Orange
  ];
  useEffect(() => {
    fetchDealsData();
  }, [dateRanges]);

  const fetchDealsData = async () => {
    setIsLoading(true);
    try {
      const startDate = dateRanges
        ? moment(dateRanges[0]).unix()
        : moment().subtract(30, "days").unix();
      const endDate = dateRanges
        ? moment(dateRanges[1]).unix()
        : moment().unix();
      const url = `get_deals?s=${startDate}&e=${endDate}`;
      const data = await req("GET", url);
      //console.log("Pipedrive deals", data);
      if (data && data.length > 0) {
        const startDateParam = moment.unix(startDate).format("YYYY-MM-DD");
        const endDateParam = moment.unix(endDate).format("YYYY-MM-DD");
        setDealStats(generateDealStats(data, startDateParam, endDateParam));
      }
    } catch (error) {
      console.error("Error fetching leads data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function generateDealStats(dealsData, startDate = null, endDate = null) {
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : new Date();

    // Filter deals based on the date range
    const dealsInRange = dealsData.filter(deal => {
        const dealDate = new Date(deal.add_time);
        return (!start || dealDate >= start) && dealDate <= end;
    });

    // Generate deals per day per BDM data
    const dealsPerDayPerBDM = dealsInRange.reduce((acc, deal) => {
      const dealDate = moment(deal.add_time).format('YYYY-MM-DD');
      const bdmName = deal.user_id.name;
      
      if (!acc[dealDate]) {
        acc[dealDate] = {};
      }
      
      acc[dealDate][bdmName] = (acc[dealDate][bdmName] || 0) + 1;
      return acc;
    }, {});

    // Total number of deals
    const totalDeals = dealsInRange.length;

    // Deals by BDM (user_id.name)
    const dealsByBDM = dealsInRange.reduce((acc, deal) => {
        const bdmName = deal.user_id.name;
        acc[bdmName] = (acc[bdmName] || 0) + 1;
        return acc;
    }, {});

    // Most recent deal
    const mostRecentDeal = dealsInRange.reduce((latest, deal) => {
        const dealDate = new Date(deal.add_time);
        return dealDate > new Date(latest.add_time) ? deal : latest;
    }, dealsInRange[0]);

    const data = [
        { label: "Total Deals", value: totalDeals },
        { label: "Deals by BDM", value: dealsByBDM },
        { label: "Most Recent Deal", value: mostRecentDeal },
        { label: "Deals per Day per BDM", value: dealsPerDayPerBDM },
    ];
  
    console.log("dealStats", data);
    return data;
  }

  const renderDealsPerDayChart = () => {
    if (!dealStats) return null;

    const dealsPerDayPerBDM = dealStats.find(stat => stat.label === "Deals per Day per BDM")?.value || {};
    const dates = Object.keys(dealsPerDayPerBDM).sort();
    const bdms = [...new Set(dates.flatMap(date => Object.keys(dealsPerDayPerBDM[date])))];

    const bdmColorMap = {
      "kirk": purplePalette[0],  // Lavender
      "Dennis": bluePalette[0],  // Ocean Blue
      "Matt Miliano": orangePalette[0]  // Amber
    };

    const datasets = bdms.map((bdm) => {
      const data = dates.map(date => dealsPerDayPerBDM[date][bdm] || 0);
      const hasDeals = data.some(value => value > 0);

      return {
        label: bdm,
        data: data,
        backgroundColor: bdmColorMap[bdm] || "#B3A6FF", // Default to Lavender if not specified
        hidden: !hasDeals, // Hide the dataset if there are no deals
      };
    }).filter(dataset => !dataset.hidden); // Remove hidden datasets from the final array

    const chartData = {
      labels: dates,
      datasets: datasets,
    };

    const options = {
      responsive: true,
      scales: {
        x: { stacked: true },
        y: { stacked: true }
      },
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
        },
        title: {
          display: true,
          
          text: 'Deals per Day by BDM',
        },
      },
    };

    return <Bar data={chartData} options={options} />;
  };

  const formatDate = (date) => moment(date).format("DD/MM/YYYY");

  if (isLoading) return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={6}
      className={`${
        pageFilterByType !== "unfiltered" && pageFilterByType !== "contract"
          ? "d-none"
          : ""
      }`}
    >
      <div className="card card-stat-summary animate-bg-primary">
        <div className="card-body">
          <div className="card-stat-summary--title">Pipeline Deals</div>
          <div className="card-stat-summary--total-wrapper">
            <div>Loading...</div>
          </div>
        </div>
        <div className="card-bg">
          <img
            src={fileContractIcon}
            alt="File Contract Icon"
            className="bg-icon"
          />
        </div>
      </div>
    </Grid>
  );
  if (!dealStats) return null;

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={6}
      lg={6}
      className={`${
        pageFilterByType !== "unfiltered" && pageFilterByType !== "contract"
          ? "d-none"
          : ""
      }`}
    >
      <div className="card card-stat-summary">
        <div className="card-body">
          <div className="card-stat-summary--title">PipeDrive Deals</div>
          <div className="card-stat-summary--total-wrapper"></div>
          <Grid container spacing={2}>
              <Grid item xs={12}  sm={12} md={6}>
                <div className="card-stat-summary--total">
                  {dealStats[0].value}
                  <div className="card-stat-summary--total-subtitle">
                    Total deals
                  </div>
                </div>
              </Grid>
            </Grid>
          {renderDealsPerDayChart()}
          {dealStats[2].value && (
            <div className="most-recent-deal" style={{ marginTop: '1rem' }}>
              <h4>Most Recent Deal</h4>
              <p><strong>Title:</strong> {dealStats[2].value.title}</p>
              <p><strong>Added:</strong> {formatDate(dealStats[2].value.add_time)}</p>
              <p><strong>BDM:</strong> {dealStats[2].value.owner_name}</p>
            </div>
          )}
          <div className="card-stat-summary--meta">
            <div className="card-stat-summary--meta-timeline">
              <span className="text-persiangreen">Date range:</span>{" "}
              {formatDate(dateRanges[0])} - {formatDate(dateRanges[1])}
            </div>
          </div>
        </div>
        <div className="card-bg">
          <img
            src={fileContractIcon}
            alt="File Contract Icon"
            className="bg-icon"
          />
        </div>
      </div>
    </Grid>
  );
};
